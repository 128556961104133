import React, { Component } from "react";

class About extends Component {
  render() {
    if (this.props.data) {
      var fullname = this.props.data.fullname;
      var profilepic = "images/" + this.props.data.image;
      var bio = this.props.data.bio;
      // var street = this.props.data.address.street;
      // var city = this.props.data.address.city;
      // var state = this.props.data.address.state;
      // var zip = this.props.data.address.zip;
      // var phone= this.props.data.phone;
      var email = this.props.data.email;
      // var resumeDownload = this.props.data.resumedownload;
      var github = this.props.data.social[0].url;
      var linkedin = this.props.data.social[1].url;
    }

    return (
      <section id="about">
        <div className="row">
          <div className="three columns">
            <img src={profilepic} alt="Fabien Lim Profile Pic" />
          </div>
          <div className="nine columns main-col">
            <h2>About Me</h2>

            <p>{bio}</p>
            <div className="row">
              <div className="columns contact-details">
                <h2>Contact Details</h2>
                <p className="address">
                  <div>{fullname}</div>
                  {/* <div><a href="https://t.me/fabienlimzk">Message me on Telegram</a></div> */}
                  <div>
                    <a href={`mailto:${email}`}>{email}</a>
                  </div>
                  <div>
                    <a href={github}>GitHub</a>
                  </div>
                  <div>
                    <a href={linkedin}>LinkedIn</a>
                  </div>
                </p>
              </div>
              {/* <div className="columns download">
                  <p>
                     <a href={resumeDownload} className="button"><i className="fa fa-download"></i>Download Resume</a>
                  </p>
               </div> */}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
